<template>
  <div style="width: 100%">
    <div class="card" style="width: 100%; border-color: #4d5a70 !important">
      <div
        class="card-header"
        :style="
          'background:' +
          ' #4d5a70 ' +
          '!important;width:100%;color:#FFFFFF;font-weight:bold;'
        "
      >
        <span>{{`${$t('label.DISCHARGE')} - ${$t('label.LOAD')} - ${$t('label.Restow')}`}}</span>
      </div>
      <div class="card-body">
        <CRow class="m-0">
          <CCol sm="12" lg="4" class="mb-2">
            <div class="card-table">
              <CRow class="m-0 table-header p-2" style="background: #f5bd00;">
                <div>
                  <CIcon name="cil-arrow-bottom" class="mr-1"/>
                  <b>{{ $t('label.DISCHARGE') }}</b>
                </div>
                <b>{{ getNumber(Total.Discharge, 2) }}</b>
              </CRow>
              <div class="table-body py-2" :style="`height: ${TableHeight}px`">
                <CRow class="m-0">
                  <CCol sm="8">
                    <b>{{ $t('label.packaging') }}</b>
                  </CCol>
                  <CCol sm="4" class="text-center">
                    <b>{{ $t('label.quantity') }}</b>
                  </CCol>
                </CRow>
                <CRow class="m-0" v-for="Discharge in DischargeJson">
                  <CCol sm="8">
                    {{ PackagingName(Discharge) }}
                  </CCol>
                  <CCol sm="4" class="text-center">
                    <b>{{ getNumber(Discharge.Quantity, 2) }}</b>
                  </CCol>
                </CRow>
              </div>
              <div class="footer-table py-2" style="background: #ffd966;">
                <div class="text-center px-3">
                  <b>{{ getNumber(Total.Discharge, 2) }}</b>
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="4" class="mb-2">
            <div class="card-table">
              <CRow class="m-0 table-header p-2" style="background: #7c90ad;">
                <div>
                  <CIcon name="cil-arrow-top" class="mr-1"/>
                  <b>{{ $t('label.LOAD') }}</b>
                </div>
                <b>{{ getNumber(Total.Loading, 2) }}</b>
              </CRow>
              <div class="table-body py-2" :style="`height: ${TableHeight}px`">
                <CRow class="m-0">
                  <CCol sm="8">
                    <b>{{ $t('label.packaging') }}</b>
                  </CCol>
                  <CCol sm="4" class="text-center">
                    <b>{{ $t('label.quantity') }}</b>
                  </CCol>
                </CRow>
                <CRow class="m-0" v-for="Loading in LoadingJson">
                  <CCol sm="8">
                    {{ PackagingName(Loading) }}
                  </CCol>
                  <CCol sm="4" class="text-center">
                    <b>{{ getNumber(Loading.Quantity, 2) }}</b>
                  </CCol>
                </CRow>
              </div>
              <div class="footer-table py-2" style="background: #b4c7e7;">
                <div class="text-center px-3">
                  <b>{{ getNumber(Total.Loading, 2) }}</b>
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="4" class="mb-2">
            <div class="card-table">
              <CRow class="m-0 table-header p-2" style="background: #51842f;">
                <div>
                  <CIcon name="cil-swap-horizontal" class="mr-1"/>
                  <b>{{ $t('label.Restow') }}</b>
                </div>
                <b>{{ getNumber(Total.Restow, 2) }}</b>
              </CRow>
              <div class="table-body py-2" :style="`height: ${TableHeight}px`">
                <CRow class="m-0">
                  <CCol sm="8">
                    <b>{{ $t('label.packaging') }}</b>
                  </CCol>
                  <CCol sm="4" class="text-center">
                    <b>{{ $t('label.quantity') }}</b>
                  </CCol>
                </CRow>
                <CRow class="m-0" v-for="Restow in RestowJson">
                  <CCol sm="8">
                    {{ PackagingName(Restow) }}
                  </CCol>
                  <CCol sm="4" class="text-center">
                    <b>{{ getNumber(Restow.Quantity, 2) }}</b>
                  </CCol>
                </CRow>
              </div>
              <div class="footer-table py-2" style="background: #c5e0b4;">
                <div class="text-center px-3">
                  <b>{{ getNumber(Total.Restow, 2) }}</b>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { NumberFormater } from "@/_helpers/funciones";

//data
function data() {
  return {
  };
}

//methods
function PackagingName(item) {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item['PackagingName'+_lang] ?? '';
}

function getNumber(Number, Decimal) {
  return NumberFormater.formatNumber(Number, Decimal)
}

//computed
function DischargeJson() {
  let DischargeJson = this.DischargeLoading?.DischargeJson ?? [];
  return DischargeJson.filter(item => item.PackagingId);
}

function LoadingJson() {
  let LoadingJson = this.DischargeLoading?.LoadingJson ?? [];
  return LoadingJson.filter(item => item.PackagingId);
}

function RestowJson() {
  let RestowJson = this.DischargeLoading?.RestowJson ?? [];
  return RestowJson.filter(item => item.PackagingId);
}

function Total() {
  let DischargeJson = this.DischargeLoading?.DischargeJson ?? [];
  let LoadingJson = this.DischargeLoading?.LoadingJson ?? [];
  let RestowJson = this.DischargeLoading?.RestowJson ?? [];
  let Total = {
    Discharge: DischargeJson?.find(item => item.PackagingNameEs == 'TOTAL')?.Quantity ?? 0,
    Loading: LoadingJson?.find(item => item.PackagingNameEs == 'TOTAL')?.Quantity ?? 0,
    Restow: RestowJson?.find(item => item.PackagingNameEs == 'TOTAL')?.Quantity ?? 0,
  }
  return Total;
}

function TableHeight() {
  if (this.DischargeJson?.length > this.LoadingJson?.length) {
    if (this.DischargeJson?.length > this.RestowJson?.length) {
      return this.DischargeJson?.length > 4 ? (this.DischargeJson?.length * 18) + 34 : 106;
    } else {
      return this.RestowJson?.length > 4 ? (this.RestowJson?.length * 18) + 34 : 106;
    }
  } else if(this.LoadingJson?.length > this.RestowJson?.length){
    return this.LoadingJson?.length > 4 ? (this.LoadingJson?.length * 18) + 34 : 106;
  }else{
    return this.RestowJson?.length > 4 ? (this.RestowJson?.length * 18) + 34 : 106;
  }
  
}

export default {
  name: "operations-summary-general-cargo",
  data,
  components: {},
  props: {
    DischargeLoading: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    PackagingName,
    getNumber,
  },
  computed: {
    DischargeJson,
    LoadingJson,
    RestowJson,
    Total,
    TableHeight,
    ...mapState({
      tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
      tabsIndex: state=> state.situacionOperativaActual.tabIndex,
    })
  },
  watch: {

  },
};
</script>
<style scoped>
.card-table{
  border: 1px solid #4d5a70;
  b {
    font-weight: bold;
  }
  .table-header {
    justify-content: space-between;
    color: #ffffff;
  }
  .table-body{
    min-height: 106px;
  }
  .footer-table{
    justify-content: flex-end;
    display: flex;

    div {
      min-width: 33.33%;
    }
  }
}

</style>